import { Controller } from "stimulus";
import Rails from '@rails/ujs';
const Uppy = require('@uppy/core')
const UppyEN = require('@uppy/locales/lib/en_US')
const UppyPT = require('@uppy/locales/lib/pt_BR')
const Dashboard = require('@uppy/dashboard')
// const ActiveStorageUpload = require('uppy-activestorage-upload')
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'
const ImageEditor = require('@uppy/image-editor')

export default class extends Controller {
  static targets = [ "input", "container" ]
  static values = { ratio: Number, submit: Boolean, background: Boolean }

  connect() {
    // console.log("UPPY", this.element)
    if(this.containerTarget){
      this.setupUppy(this.containerTarget)
    }
  }

  setupUppy(element) {
    let trigger = this.inputTarget
    let form = element.closest('form')
    let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
    let field_name = element.dataset.uppy
    
    
    trigger.addEventListener("click", (event) => event.preventDefault())

    let uppy = new Uppy({
      // autoProceed: true,
      allowMultipleUploads: false,
      logger: Uppy.debugLogger,
      locale: I18n.locale == 'pt' ? UppyPT : UppyEN,
      restrictions: {
        maxFileSize: 10*1024*1024,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: null,
        allowedFileTypes: ['image/*']
      }
    })

    uppy.use(Dashboard, {
      trigger: trigger,
      closeAfterFinish: true,
      autoOpenFileEditor: true,
    })

    uppy.use(ImageEditor, {
      target: Dashboard,
      quality: 0.8,
      id: 'pteImageEditor',
      cropperOptions: {
        viewMode: 1,
        background: false,
        autoCropArea: 1,
        responsive: true,
        aspectRatio: (this.ratioValue ? this.ratioValue : null),
        autoCrop: true
      },
      actions: {
        revert: true,
        rotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: true,
        cropWidescreen: false,
        cropWidescreenVertical: false,
      }
    })

    uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    })

    uppy.on('complete', (result) => {
      // Rails.ajax
      // or show a preview:
      this.inputTarget.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

      result.successful.forEach(file => {
        this.appendUploadedFile(element, file, field_name)
        this.setPreview(element, file)
        if(this.submitValue == true) Rails.fire(this.containerTarget.closest("form"), 'submit');
      })

      uppy.reset()
    })
  }

  appendUploadedFile(element, file, field_name) {
    const hiddenField = document.createElement('input')

    hiddenField.setAttribute('type', 'hidden')
    hiddenField.setAttribute('name', field_name)
    hiddenField.setAttribute('data-pending-upload', true)
    hiddenField.setAttribute('value', file.response.signed_id)

    element.appendChild(hiddenField)
  }

  setPreview(element, file) {
    let preview = element.querySelector('[data-behavior="uppy-preview"]')
    if (preview) {
      let src = (file.preview) ? file.preview : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
      preview.src = src
    }
  }
}